import rehypeRaw from "rehype-raw"
import rehypeSanitize from "rehype-sanitize"
import remarkGfm from "remark-gfm"

import React, { useContext, useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import { useLocation, useNavigate } from "react-router-dom"

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline"
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
  Grid,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { AcceptStatusRequestType } from "../../../../../models/employee/useSchedule/days/type"
import {
  deleteSchedule,
  useScheduleInformationState,
} from "../../../../../models/employee/useSchedule/show"
import {
  Loading,
  ScheduleInformation,
  ScheduleInformations,
} from "../../../../../models/employee/useSchedule/show/type"
import { AuthContext } from "../../../../../providers/AuthProvider"
import enTranslations from "../../../../../translations/employeeSchedule/employeeShowScheduleModal/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeShowScheduleModal/ja"
import { dateStatus } from "../../../../../utils/date"
import { formatDateForSafari } from "../../../../../utils/dateTimeFormat"
import DateFormatter from "./../../../../public/parseDate"
import { RecurringDeleteModalComponent } from "./RecurringDeleteModal"
import { SeatOrMeetingRoomTableComponent } from "./SeatOrMeetingRoomTableComponent"
import { ShareScheduleUserableTableComponent } from "./ShareScheduleUserableTable"

interface Props {
  date: Date
  scheduleShowData: Loading<ScheduleInformations>
  isOpen: boolean
  onApproveSubmit: (data: AcceptStatusRequestType) => void
  close: () => void
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  border: "1px solid #707070",
  boxShadow: 24,
  p: 4,
  height: "80%",
  overflow: "auto",
}

const englishWeekDays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
]

interface DateProps {
  date: Date
  scheduleInformation: ScheduleInformation
  scheduleShowData: Loading<ScheduleInformations>
  index: number
}

const toDoubleDigits = (num: number) => {
  let numString = String(num)
  if (numString.length === 1) {
    numString = "0" + numString
  }
  return numString
}

//Outlookからの本文が変なCSSが含めるから、それを解消ための定義
const customSanitizeSchemaCSS = {
  tagNames: [
    "div",
    "p",
    "i",
    "span",
    "a",
    "hr",
    "style",
    "table",
    "tbody",
    "tr",
    "td",
    "s",
    "b",
    "sub",
    "sup",
    "u",
    "blockquote",
    "ul",
    "ol",
    "li",
    "strike",
  ],
  attributes: {
    "*": ["class", "style", "id", "lang", "href", "width", "height", "alt"],
  },
  protocols: {
    href: ["http", "https"],
    src: ["http", "https"],
  },
  clobber: [],
  clobberPrefix: "",
  strip: [],
}

function preprocessMarkdown(markdown: string) {
  // 特定のHTMLコンテンツを一致させるための正規表現パターン
  // ここでは、例として与えられたHTMLコンテンツに対応するパターンを用います。
  // `\s*`は任意の数の空白を表し、`[\s\S]*?`は改行を含む任意の文字の最短マッチを表します。
  const pattern =
    /<p class="MsoNormal">\s*<span lang="EN-US"(?: style="[^"]*")?>\s*<img width="\d+" height="\d+" id="[^"]+" alt="[^"]*" style="[^"]+">\s*<\/span>(?:<span style="[^"]*">[\s\S]*?<\/span>)?\s*<\/p>/g

  // 特定のHTMLコンテンツを削除
  const cleanedMarkdown = markdown.replace(pattern, "")
  return cleanedMarkdown
}

const SpotDateComponent: React.FC<DateProps> = ({
  date,
  scheduleInformation,
  scheduleShowData,
}) => {
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // 開始日時と終了日時のDateオブジェクトを作成
  const startDate = new Date(
    formatDateForSafari(scheduleInformation.start_time.toString())
  )
  const endDate = new Date(
    formatDateForSafari(scheduleInformation.end_time.toString())
  )

  if (
    scheduleShowData.value[0].schedules[0].end_time !==
    scheduleInformation.end_time
  ) {
    // 現在時刻でチェックアウトの時間表示
    return (
      <Typography
        id="modal-modal-description"
        sx={{ mt: 1, fontSize: "0.8rem", color: "#707070" }}
      >
        {/* 開始日時 */}
        <DateFormatter date={scheduleInformation.schedules[0].start_time} />~
        {/* 終了時間 */}
        <DateFormatter date={scheduleInformation.schedules[0].end_time} />
      </Typography>
    )
  } else {
    // 予定時刻でのチェックアウトの時間表示
    return (
      <Typography
        id="modal-modal-description"
        sx={{ mt: 1, fontSize: "0.8rem", color: "#707070" }}
      >
        {/* 開始日時 */}
        {`${startDate.getMonth() + 1}
        ${translations.month}
        ${startDate.getDate()}
        ${translations.day}(${translations.weekdays[startDate.getDay()]}
        ${translations.week})
        ${new Date(
          formatDateForSafari(scheduleInformation.start_time.toString())
        ).getHours()}:
        ${toDoubleDigits(
          new Date(
            formatDateForSafari(scheduleInformation.start_time.toString())
          ).getMinutes()
        )}`}{" "}
        ~{" "}
        {`${
          new Date(
            formatDateForSafari(scheduleInformation.end_time.toString())
          ).getMonth() + 1
        }
        ${translations.month}
        ${new Date(
          formatDateForSafari(scheduleInformation.end_time.toString())
        ).getDate()}
        ${translations.day}(${translations.weekdays[endDate.getDay()]}
        ${translations.week})`}
        {/* 時間 */}
        {new Date(
          formatDateForSafari(scheduleInformation.end_time.toString())
        ).getHours()}
        :
        {toDoubleDigits(
          new Date(
            formatDateForSafari(scheduleInformation.end_time.toString())
          ).getMinutes()
        )}
      </Typography>
    )
  }
}

// モーダルの日時取得
const setScheduleDates = (baseDate: Date, startTime: Date, endTime: Date) => {
  const startDate = new Date(baseDate)
  const endDate = new Date(baseDate)
  startDate.setHours(startTime.getHours(), startTime.getMinutes())
  endDate.setHours(endTime.getHours(), endTime.getMinutes())
  return { startDate, endDate }
}

// 繰り返し予定
const RecurringDateComponent: React.FC<DateProps> = ({
  date,
  scheduleInformation,
}) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const { startDate: currentStartDate, endDate: currentEndDate } =
    setScheduleDates(
      date,
      new Date(scheduleInformation.start_time),
      new Date(scheduleInformation.end_time)
    )

  if (
    scheduleInformation.schedules[0] &&
    scheduleInformation.schedules[0].end_time !== scheduleInformation.end_time
  ) {
    return (
      <Typography
        id="modal-modal-description"
        sx={{ mt: 1, fontSize: "0.8rem", color: "#707070" }}
      >
        <DateFormatter date={currentStartDate} /> ~
        <DateFormatter date={currentEndDate} />
      </Typography>
    )
  } else {
    return (
      <Typography
        id="modal-modal-description"
        sx={{ mt: 1, fontSize: "0.8rem", color: "#707070" }}
      >
        {/* 開始日時 */}
        {`${date.getMonth() + 1}
        ${translations.month}
        ${date.getDate()}
        ${translations.day}(${translations.weekdays[date.getDay()]}
        ${translations.week})
        ${new Date(
          formatDateForSafari(scheduleInformation.start_time.toString())
        ).getHours()}:
        ${toDoubleDigits(
          new Date(
            formatDateForSafari(scheduleInformation.start_time.toString())
          ).getMinutes()
        )}`}{" "}
        ~{" "}
        {`${
          new Date(
            formatDateForSafari(scheduleInformation.end_time.toString())
          ).getMonth() + 1
        }
        ${translations.month}
        ${new Date(
          formatDateForSafari(scheduleInformation.end_time.toString())
        ).getDate()}
        ${translations.day}(${translations.weekdays[date.getDay()]}
        ${translations.week})`}
        {/* 時間 */}
        {new Date(
          formatDateForSafari(scheduleInformation.end_time.toString())
        ).getHours()}
        :
        {toDoubleDigits(
          new Date(
            formatDateForSafari(scheduleInformation.end_time.toString())
          ).getMinutes()
        )}
      </Typography>
    )
  }
}

// const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"]

export const EmployeeScheduleDayShowModal: React.FC<Props> = ({
  date,
  isOpen,
  close,
  scheduleShowData,
  onApproveSubmit,
}) => {
  const { dayOfWeek } = dateStatus()
  const location = useLocation()
  const [recurringDeleteModal, setRecurringDeleteModal] =
    useState<boolean>(false)
  const navigate = useNavigate()
  const { scheduleInformation, updateScheduleInformation } =
    useScheduleInformationState()
  const [isMySchedule, setIsMySchedule] = useState<boolean>(false)
  const [isSecret, setIsSecret] = useState<boolean>(false)

  const { currentUser } = useContext(AuthContext)

  const myScheduleData = scheduleInformation.schedules.find((schedule) => {
    return schedule.userable.email === currentUser?.attributes.email
  })

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const today = new Date(new Date().setHours(0, 0, 0, 0))
  const { startDate: currentStartDate } = setScheduleDates(
    date,
    new Date(scheduleInformation.start_time),
    new Date(scheduleInformation.end_time)
  )
  // 未来の予定で編集、削除できるよう設定
  const isFutureDate = currentStartDate >= today

  useEffect(() => {
    // 取得した予定がログインユーザーの参加予定かどうか判定
    setIsMySchedule(
      scheduleInformation.schedules.some(
        (schedule) => schedule.userable.email === currentUser?.attributes.email
      )
    )
    setIsSecret(scheduleInformation.schedules[0]?.is_secret)
  }, [scheduleInformation])

  const weekDaysStr = () => {
    let weekDaysStr = ""
    englishWeekDays.forEach((weekDay, index) => {
      if (
        scheduleInformation.weekly_recurring_type &&
        eval(`scheduleInformation.weekly_recurring_type.${weekDay}`)
      ) {
        weekDaysStr += translations.weekdays[index] + "・"
      }
    })
    if (scheduleInformation.weekly_recurring_type.every_week === 1) {
      weekDaysStr = translations.everyWeek + " " + weekDaysStr
    } else {
      weekDaysStr =
        `${scheduleInformation.weekly_recurring_type.every_week}${translations.weekly}` +
        " " +
        weekDaysStr
    }
    return weekDaysStr.slice(0, -1)
  }

  // ステータスタイトル言語切り替え
  type ScheduleStatuses = {
    [key: string]: string
  }

  type Translations = {
    schedule_statuses: ScheduleStatuses
  }

  const getLocalizedStatusText = (status: string) => {
    const translations: Translations =
      language === "en" ? enTranslations : jaTranslations
    return translations.schedule_statuses[status] || status
  }

  const handleTitleDisplay = (scheduleInformation: ScheduleInformation) => {
    const isMySchedule = scheduleInformation.schedules.some(
      (schedule) => schedule.userable.email === currentUser?.attributes.email
    )
    const isSecret = scheduleInformation.schedules[0]?.is_secret
    if (!isMySchedule && isSecret) {
      return translations.Private
    } else {
      return getLocalizedStatusText(scheduleInformation.schedule_title)
    }
  }
  useEffect(() => {
    if (scheduleShowData.value.length != 0) {
      updateScheduleInformation(scheduleShowData.value[0])
    }
  }, [scheduleShowData.value])

  const weeKDayStr = (numberDayOfWeek: number) => {
    let text = ""
    switch (numberDayOfWeek) {
      case 1:
        text = translations.first
        break
      case 2:
        text = translations.second
        break
      case 3:
        text = translations.third
        break
      case 4:
        text = translations.fourth
        break
      case 5:
        text = translations.last
        break
    }
    return text
  }
  return (
    <>
      {(scheduleInformation.weekly_recurring_type ||
        scheduleInformation.monthly_recurring_type) && (
        <RecurringDeleteModalComponent
          date={date}
          schedule_information_id={scheduleInformation.id}
          recurringDeleteModal={recurringDeleteModal}
          setRecurringDeleteModal={setRecurringDeleteModal}
        />
      )}
      <Modal
        open={isOpen}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {scheduleShowData.loading ? (
          <Box
            sx={style}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={style}>
            <Grid container>
              <Grid item sm={4}>
                {scheduleShowData.value.map((scheduleInformation, index) => (
                  <Box
                    key={index}
                    sx={{
                      '&[aria-selected="true"]': {
                        backgroundColor: "rgba(232, 248, 245, 0.5)",
                      },
                      padding: "1rem",
                    }}
                    aria-selected={index === 0 ? true : false}
                    onClick={(e) => {
                      const list = e.currentTarget.parentElement?.children
                      if (list) {
                        Array.from(list).forEach((child) => {
                          child.setAttribute("aria-selected", "false")
                        })
                      }
                      e.currentTarget.setAttribute("aria-selected", "true")
                      updateScheduleInformation(scheduleInformation)
                    }}
                  >
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h1"
                      sx={{
                        color: "#707070",
                        fontWeight: "bold",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {handleTitleDisplay(scheduleInformation)}
                    </Typography>
                    {!scheduleInformation.weekly_recurring_type &&
                    !scheduleInformation.monthly_recurring_type ? (
                      // new Date(scheduleInformation.end_time).getDate() !=
                      <SpotDateComponent
                        date={date}
                        scheduleInformation={scheduleInformation}
                        scheduleShowData={scheduleShowData}
                        index={index}
                      />
                    ) : (
                      <RecurringDateComponent
                        date={date}
                        scheduleInformation={scheduleInformation}
                        scheduleShowData={scheduleShowData}
                        index={index}
                      />
                    )}
                  </Box>
                ))}
              </Grid>
              <Grid item sm={1}>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ margin: "4rem 2rem 0 2rem", height: "80%" }}
                />
              </Grid>
              <Grid item sm={7}>
                <Grid container>
                  <Grid item sm={12}>
                    {scheduleInformation.schedule_creator_flag &&
                      !scheduleInformation.is_microsoft &&
                      isFutureDate && (
                        <Box display="flex" justifyContent="end">
                          <Box>
                            <Button
                              onClick={() => {
                                if (
                                  scheduleInformation.weekly_recurring_type ||
                                  scheduleInformation.monthly_recurring_type
                                ) {
                                  setRecurringDeleteModal(true)
                                } else {
                                  deleteSchedule(
                                    scheduleInformation.id,
                                    "all_schedule",
                                    date
                                  ).then(() => {
                                    location.pathname.match("/employee/home")
                                      ? navigate("/employee/home")
                                      : navigate("/employee/calendar/days")
                                    window.location.reload()
                                    localStorage.setItem(
                                      "alertContent",
                                      translations.ScheduleDeleted
                                    )
                                  })
                                }
                              }}
                            >
                              <DeleteOutlineIcon />
                            </Button>
                          </Box>
                          {/* 共同予定の作成者のみ編集できる（スケジュールの数で共同予定か分かる） */}
                          {(scheduleInformation.schedules.length > 1 ||
                            scheduleInformation.schedules[0].schedule_type ===
                              "event" ||
                            (scheduleInformation.schedules[0].schedule_type ===
                              "status" &&
                              scheduleInformation.schedules[0].reservable)) && (
                            <Box>
                              <Button
                                onClick={() => {
                                  navigate(
                                    `/employee/calendar/edit/${scheduleInformation.id}`,
                                    {
                                      state: {
                                        scheduleInformation:
                                          scheduleInformation,
                                        navigateFrom: window.location.pathname,
                                      },
                                    }
                                  )
                                }}
                              >
                                <DriveFileRenameOutlineIcon />
                              </Button>
                            </Box>
                          )}
                        </Box>
                      )}
                  </Grid>
                </Grid>
                <Typography color="red">
                  {scheduleInformation.canceled ? translations.autoCancel : ""}
                </Typography>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h1"
                  sx={{
                    color: "#707070",
                    fontWeight: "bold",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {!isMySchedule && isSecret
                    ? translations.Private
                    : getLocalizedStatusText(
                        scheduleInformation.schedule_title
                      )}
                </Typography>
                {!scheduleInformation.weekly_recurring_type &&
                !scheduleInformation.monthly_recurring_type ? (
                  // new Date(scheduleInformation.end_time).getDate() !=
                  <SpotDateComponent
                    date={date}
                    scheduleInformation={scheduleInformation}
                    scheduleShowData={scheduleShowData}
                    index={0}
                  />
                ) : (
                  <RecurringDateComponent
                    date={date}
                    scheduleInformation={scheduleInformation}
                    scheduleShowData={scheduleShowData}
                    index={0}
                  />
                )}
                {scheduleInformation.weekly_recurring_type &&
                  scheduleInformation.weekly_recurring_type.id != 0 && (
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 1, fontSize: "0.8rem", color: "#707070" }}
                    >
                      {weekDaysStr()}
                      {translations.week}
                    </Typography>
                  )}
                {scheduleInformation.monthly_recurring_type &&
                  scheduleInformation.monthly_recurring_type.id != 0 &&
                  scheduleInformation.monthly_recurring_type
                    .daily_or_week_day == "daily" && (
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 1, fontSize: "0.8rem", color: "#707070" }}
                    >
                      {scheduleInformation.monthly_recurring_type
                        .every_monthly == 1
                        ? `${translations.everyMonth} ${scheduleInformation.monthly_recurring_type.day}${translations.day}`
                        : `${scheduleInformation.monthly_recurring_type.every_monthly}${translations.Monthly} ${scheduleInformation.monthly_recurring_type.day}${translations.day}`}
                    </Typography>
                  )}
                {scheduleInformation.monthly_recurring_type &&
                  scheduleInformation.monthly_recurring_type.id != 0 &&
                  scheduleInformation.monthly_recurring_type
                    .daily_or_week_day == "week_day" && (
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 1, fontSize: "0.8rem", color: "#707070" }}
                    >
                      {scheduleInformation.monthly_recurring_type
                        .every_monthly == 1
                        ? `${translations.everyMonth} ${weeKDayStr(
                            scheduleInformation.monthly_recurring_type
                              .number_day_of_week
                          )} ${
                            translations.weekdays[
                              scheduleInformation.monthly_recurring_type
                                .day_of_week
                            ]
                          } ${translations.week}`
                        : `${
                            scheduleInformation.monthly_recurring_type
                              .every_monthly
                          }${translations.Monthly} ${weeKDayStr(
                            scheduleInformation.monthly_recurring_type
                              .number_day_of_week
                          )}${
                            translations.weekdays[
                              scheduleInformation.monthly_recurring_type
                                .day_of_week
                            ]
                          }${translations.week}`}
                    </Typography>
                  )}
                {!isMySchedule && isSecret ? (
                  translations.Private
                ) : (
                  <>
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h1"
                        sx={{ color: "#707070", fontWeight: "bold", mt: 2 }}
                      >
                        {translations.SeatLocation}
                      </Typography>
                    </Box>
                    <SeatOrMeetingRoomTableComponent
                      schedules={scheduleInformation.schedules}
                    />
                    <ShareScheduleUserableTableComponent
                      schedules={scheduleInformation.schedules}
                    />
                    {scheduleInformation.message_content ? (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        marginTop={4}
                      >
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    color: "#707070",
                                    fontWeight: "bold",
                                    fontSize: "1.25rem",
                                  }}
                                >
                                  {translations.Description}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <Box marginLeft={2}>
                                  <ReactMarkdown
                                    rehypePlugins={[
                                      rehypeRaw,
                                      [rehypeSanitize, customSanitizeSchemaCSS],
                                    ]}
                                    remarkPlugins={[remarkGfm]}
                                  >
                                    {preprocessMarkdown(
                                      scheduleInformation.message_content
                                    )}
                                  </ReactMarkdown>
                                </Box>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    ) : (
                      ""
                    )}
                  </>
                )}
                {scheduleInformation.accept_flag &&
                  myScheduleData &&
                  !scheduleInformation.schedule_creator_flag &&
                  !scheduleInformation.is_microsoft && (
                    <Grid container spacing={2} marginTop="2rem">
                      <Grid
                        item
                        sm={4}
                        md={4}
                        style={{
                          fontSize: "15px",
                          marginTop: "8.2px",
                        }}
                      >
                        {translations.invitation}
                      </Grid>
                      <Grid item sm={4} md={4}>
                        <Button
                          variant="outlined"
                          fullWidth
                          sx={{ borderRadius: "30px" }}
                          onClick={() => {
                            onApproveSubmit({
                              id: myScheduleData.id,
                              accept_status: false,
                            })
                          }}
                          disabled={!myScheduleData.accept_status}
                        >
                          {translations.Decline}
                        </Button>
                      </Grid>
                      <Grid item sm={4} md={4}>
                        <Button
                          variant="contained"
                          fullWidth
                          sx={{ borderRadius: "30px" }}
                          onClick={() => {
                            onApproveSubmit({
                              id: myScheduleData.id,
                              accept_status: true,
                            })
                          }}
                          disabled={myScheduleData.accept_status}
                        >
                          {translations.Accept}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Box>
        )}
      </Modal>
    </>
  )
}
