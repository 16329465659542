import React, { useState } from "react"
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormSetValue,
} from "react-hook-form"

import AddIcon from "@mui/icons-material/Add"
import SearchIcon from "@mui/icons-material/Search"
import {
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
  Box,
} from "@mui/material"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { EmployeeGroupsType } from "../../../../../models/employee/useEmployeeGroup/type"
import {
  SearchedEmployeeResult,
  SearchEmployeeRequestType,
} from "../../../../../models/employee/useSchedule/scheduleSearchEmployeeForm/type"
import { EmployeesType } from "../../../../../models/employee/useSchedule/type"
import { EmployeeScheduleRequestType } from "../../../../../models/employee/useSchedule/type"
import { useCompanyRelations } from "../../../../../models/public/useCompanyRelations"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleSideSearch/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleSideSearch/ja"
import { GreenArrowIcon } from "../../../../public/GreenArrowIcon"
import { EmployeeScheduleSearchedModal } from "../EmployeeScheduleSearchedModal"

const CustomPrimaryButtonText = styled(Typography)(({ theme }) => ({
  letterSpacing: "0.7px",
  fontSize: "16px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  ml: theme.spacing(1),
}))

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
}))

const CustomSecondaryButtonText = styled(Typography)(({ theme }) => ({
  letterSpacing: "0.7px",
  fontSize: "16px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  ml: theme.spacing(1),
}))

interface Props {
  newScheduleNavigate: () => void
  startDate: Date
  endDate: Date
  formatDate: (date: Date) => string
  employees: EmployeesType
  handleScheduleFetch: (data: EmployeeScheduleRequestType) => Promise<void>
  control: Control<SearchEmployeeRequestType>
  errors: FieldErrors<SearchEmployeeRequestType>
  isValid: boolean
  handleSubmit: UseFormHandleSubmit<SearchEmployeeRequestType>
  onSearchEmployeeSubmit: SubmitHandler<SearchEmployeeRequestType>
  setValue: UseFormSetValue<SearchEmployeeRequestType>
  searchedEmployeeResult: SearchedEmployeeResult
  handleEmployeeChecked: (selectedEmployeeId: number) => void
  handleEmployeeFixed: () => SearchedEmployeeResult
  employeeGroups: EmployeeGroupsType
  loading: boolean
}

export const EmployeeScheduleSideSearch: React.FC<Props> = ({
  newScheduleNavigate,
  startDate,
  endDate,
  formatDate,
  handleScheduleFetch,
  control,
  errors,
  isValid,
  handleSubmit,
  onSearchEmployeeSubmit,
  setValue,
  searchedEmployeeResult,
  handleEmployeeChecked,
  handleEmployeeFixed,
  employeeGroups,
  loading,
}: Props) => {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false)
  const { companyRelations } = useCompanyRelations()
  const [selectedBranchId, setSelectedBranchId] = useState<number>(0)

  const selectedBranch = companyRelations.branches.find(
    (branch) => branch.id === selectedBranchId
  )

  const handleOpen = () => {
    setValue("branch_id", 0)
    setValue("floor_id", 0)
    setValue("team_id", 0)
    setValue("freeword", "")
    setValue("employee_group_id", 0)
    setIsSearchModalOpen(true)
  }

  const handleClose = () => {
    setIsSearchModalOpen(false)
  }

  // 従業員を選択後確定ボタンを押した時の挙動
  const handleFixAndClose = () => {
    const uniqueSelectedEmployees = handleEmployeeFixed()
    const employeeIds = uniqueSelectedEmployees.map((employee) => {
      return employee.id
    })
    handleScheduleFetch({
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      employee_ids: employeeIds,
    })
    handleClose()
  }

  // 有効なレイアウトを持つフロアのみをフィルタリングする関数を追加
  const getAvailableFloors = (floors: any[]) => {
    const tomorrow = new Date()
    tomorrow.setHours(0, 0, 0, 0)
    tomorrow.setDate(tomorrow.getDate() + 1)

    return floors.filter((floor) => {
      return floor.layouts?.some(
        (layout: any) =>
          layout.post_status === "released" &&
          new Date(layout.release_date) < tomorrow
      )
    })
  }

  // 言語切替
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  return (
    <>
      <Grid container rowSpacing={3}>
        <Box margin="5px auto 0" width="255px" marginTop="24px">
          <Button
            fullWidth
            variant="contained"
            sx={{ borderRadius: "0.5rem" }}
            onClick={() => {
              newScheduleNavigate()
            }}
          >
            <AddIcon color="inherit" sx={{ marginRight: "10px" }} />
            <CustomPrimaryButtonText>
              {translations.createSchedule}
            </CustomPrimaryButtonText>
          </Button>
        </Box>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item container spacing={1} mb={1}>
              <Grid item display="flex">
                <SearchIcon color="primary" fontSize="small" />
                <CustomTypography fontSize="14px" color="primary" ml="0.5rem">
                  {translations.findMember}
                </CustomTypography>
              </Grid>
              <Grid item sm={12}>
                <Controller
                  control={control}
                  name={"freeword"}
                  render={({ field }) => (
                    <TextField
                      id="search_word_id"
                      label={translations.searchName}
                      variant="outlined"
                      {...field}
                      error={!!errors.freeword}
                      helperText={errors.freeword?.message}
                      fullWidth
                      sx={{
                        backgroundColor: "#707070",
                        background: "rgba(112,112,112,0.01)",
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: "12px",
                        },
                      }}
                      InputProps={{
                        sx: {
                          "& .MuiOutlinedInput-input": {
                            padding: "12px 15px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item display="flex" container spacing={1}>
              <Grid item xs={4} display="flex" alignItems="center">
                <CustomTypography fontSize="12px" color="primary">
                  {translations.location}
                </CustomTypography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  name="branch_id"
                  render={({ field }) => (
                    <Select
                      variant="outlined"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        setValue("floor_id", 0)
                        setSelectedBranchId(e.target.value as number)
                      }}
                      fullWidth
                      error={!!errors.branch_id}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                        fontSize: "12px",
                        fontFamily: "Noto Sans",
                        "& .MuiOutlinedInput-input": {
                          padding: "12px 15px",
                        },
                      }}
                      IconComponent={GreenArrowIcon}
                    >
                      <MenuItem key={0} value={0} disabled>
                        {translations.location}
                      </MenuItem>
                      {companyRelations.branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          {branch.branch_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={4} display="flex" alignItems="center">
                <CustomTypography fontSize="12px" color="primary">
                  {translations.floor}
                </CustomTypography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  name="floor_id"
                  render={({ field }) => (
                    <Select
                      variant="outlined"
                      {...field}
                      error={!!errors.floor_id}
                      fullWidth
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                        fontSize: "12px",
                        fontFamily: "Noto Sans",
                        "& .MuiOutlinedInput-input": {
                          padding: "12px 15px",
                        },
                      }}
                      IconComponent={GreenArrowIcon}
                    >
                      <MenuItem key={0} value={0} disabled>
                        {translations.floor}
                      </MenuItem>
                      {selectedBranch &&
                        getAvailableFloors(selectedBranch.floors).map(
                          (floor) => (
                            <MenuItem key={floor.id} value={floor.id}>
                              {floor.floor_name}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  )}
                />
                <FormHelperText error={!!errors.floor_id?.message}>
                  {errors.floor_id?.message}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid item display="flex" container spacing={1}>
              <Grid item xs={4} display="flex" alignItems="center">
                <CustomTypography fontSize="12px" color="primary">
                  {translations.businessUnit}
                </CustomTypography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  name="team_id"
                  render={({ field }) => (
                    <Select
                      variant="outlined"
                      {...field}
                      error={!!errors.team_id}
                      fullWidth
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                        fontSize: "12px",
                        fontFamily: "Noto Sans",
                        "& .MuiOutlinedInput-input": {
                          padding: "12px 15px",
                        },
                      }}
                      IconComponent={GreenArrowIcon}
                    >
                      <MenuItem key={0} value={0} disabled>
                        {translations.businessUnit}
                      </MenuItem>
                      {companyRelations.teams.map((team) => (
                        <MenuItem key={team.id} value={team.id}>
                          {team.team_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item display="flex" container spacing={1}>
              <Grid item xs={4} display="flex" alignItems="center">
                <CustomTypography fontSize="12px" color="primary">
                  {translations.Favorite}
                </CustomTypography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  name="employee_group_id"
                  render={({ field }) => (
                    <Select
                      variant="outlined"
                      {...field}
                      error={!!errors.employee_group_id}
                      fullWidth
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                        fontSize: "12px",
                        fontFamily: "Noto Sans",
                        "& .MuiOutlinedInput-input": {
                          padding: "12px 15px",
                        },
                      }}
                      IconComponent={GreenArrowIcon}
                    >
                      <MenuItem key={0} value={0} disabled>
                        {translations.Team}
                      </MenuItem>
                      {employeeGroups.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.group_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
            <Box sx={{ margin: "15px 0 0 6px" }}>
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  width: "255px",
                  borderRadius: "0.5rem",
                  borderWidth: "2px",
                }}
                onClick={() => {
                  handleSubmit(onSearchEmployeeSubmit)()
                  isValid && handleOpen()
                }}
              >
                <CustomSecondaryButtonText>
                  {translations.Search}
                </CustomSecondaryButtonText>
              </Button>
              <FormHelperText error={!!errors.require?.message}>
                {errors.require?.message}
              </FormHelperText>
            </Box>
          </Grid>
        </Grid>
        <EmployeeScheduleSearchedModal
          isSearchModalOpen={isSearchModalOpen}
          handleClose={handleClose}
          handleFixAndClose={handleFixAndClose}
          searchedEmployeeResult={searchedEmployeeResult}
          handleEmployeeChecked={handleEmployeeChecked}
          loading={loading}
        />
      </Grid>
    </>
  )
}
