import React, { useEffect, useState } from "react"
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormSetValue,
} from "react-hook-form"

import SearchIcon from "@mui/icons-material/Search"
import {
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import { useEmployeeGroup } from "../../../../models/employee/useEmployeeGroup"
import {
  SearchedEmployeeResult,
  SearchEmployeeRequestType,
} from "../../../../models/employee/useEmployeeSearch/type"
import { useCompanyRelations } from "../../../../models/public/useCompanyRelations"
import enTranslations from "../../../../translations/employeeStatus/employeeStatusSearch/en"
import jaTranslations from "../../../../translations/employeeStatus/employeeStatusSearch/ja"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
}))

const CustomSecondaryButtonText = styled(Typography)(({ theme }) => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  ml: theme.spacing(1),
}))

interface Props {
  control: Control<SearchEmployeeRequestType>
  errors: FieldErrors<SearchEmployeeRequestType>
  handleSubmit: UseFormHandleSubmit<SearchEmployeeRequestType>
  onSubmit: SubmitHandler<SearchEmployeeRequestType>
  setValue: UseFormSetValue<SearchEmployeeRequestType>
  setSearchedEmployeeResult?: React.Dispatch<
    React.SetStateAction<SearchedEmployeeResult>
  >
}

// 従業員検索フォーム
export const EmployeeSearchForm = ({
  control,
  errors,
  handleSubmit,
  onSubmit,
  setValue,
  setSearchedEmployeeResult,
}: Props) => {
  const { companyRelations } = useCompanyRelations()
  const employeeGroups = useEmployeeGroup().employeeGroups
  const [selectedBranchId, setSelectedBranchId] = useState<number>(0)

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // フォームを開いたときに検索結果をリセットする
  useEffect(() => {
    setSearchedEmployeeResult && setSearchedEmployeeResult([])
  }, [])

  const selectedBranch = companyRelations.branches.filter((branch) => {
    return branch.id === selectedBranchId
  })[0]

  // 有効なレイアウトを持つフロアのみをフィルタリングする関数を追加
  const getAvailableFloors = (floors: any[]) => {
    const tomorrow = new Date()
    tomorrow.setHours(0, 0, 0, 0)
    tomorrow.setDate(tomorrow.getDate() + 1)

    return floors.filter((floor) => {
      return floor.layouts?.some(
        (layout: any) =>
          layout.post_status === "released" &&
          new Date(layout.release_date) < tomorrow
      )
    })
  }

  return (
    <Grid container spacing={1} mb={1}>
      <Grid item xs={12} container spacing={1} mb={1}>
        <Grid item display="flex">
          <SearchIcon color="primary" fontSize="large" />
          <CustomTypography variant="h6" color="primary" ml="0.5rem">
            {translations.employeeMembers}
          </CustomTypography>
        </Grid>
        <Grid item sm={12}>
          <Controller
            control={control}
            name={"freeword"}
            render={({ field }) => (
              <TextField
                id="search_word_id"
                label={translations.searchName}
                variant="outlined"
                {...field}
                error={!!errors.freeword}
                helperText={errors.freeword?.message}
                fullWidth
                sx={{
                  backgroundColor: "#707070",
                  background: "rgba(112,112,112,0.01)",
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" container spacing={1}>
        <Grid item xs={4} display="flex" alignItems="center">
          <CustomTypography variant="h6" color="primary" fontSize={"16px"}>
            {translations.Location}
          </CustomTypography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            control={control}
            name="branch_id"
            render={({ field }) => (
              <Select
                variant="outlined"
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value)
                  setSelectedBranchId(Number(e.target.value))
                  setValue("floor_id", 0)
                }}
                fullWidth
                error={!!errors.branch_id}
                sx={{
                  background: "rgba(112,112,112,0.05)",
                  width: "120px",
                  // marginRight: "10px",
                  marginLeft: "35px",
                }}
                IconComponent={GreenArrowIcon}
              >
                <MenuItem key={0} value={0} disabled>
                  {translations.Location}
                </MenuItem>
                {companyRelations.branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.branch_name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={4} display="flex" alignItems="center">
          <CustomTypography variant="h6" color="primary" fontSize={"16px"}>
            {translations.Floor}
          </CustomTypography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            control={control}
            name="floor_id"
            render={({ field }) => (
              <Select
                variant="outlined"
                {...field}
                error={!!errors.floor_id}
                fullWidth
                sx={{
                  background: "rgba(112,112,112,0.05)",
                  width: "120px",
                  // marginRight: "20px",
                  marginLeft: "35px",
                }}
                IconComponent={GreenArrowIcon}
              >
                <MenuItem key={0} value={0} disabled>
                  {translations.Floor}
                </MenuItem>
                {selectedBranch &&
                  getAvailableFloors(selectedBranch.floors).map((floor) => (
                    <MenuItem key={floor.id} value={floor.id}>
                      {floor.floor_name}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
          <FormHelperText error={!!errors.floor_id?.message}>
            {errors.floor_id?.message}
          </FormHelperText>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={4} display="flex" alignItems="center">
          <CustomTypography variant="h6" color="primary" fontSize={"16px"}>
            {translations.BusinessUnit}
          </CustomTypography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            control={control}
            name="team_id"
            render={({ field }) => (
              <Select
                variant="outlined"
                {...field}
                error={!!errors.team_id}
                fullWidth
                sx={{
                  background: "rgba(112,112,112,0.05)",
                  width: "120px",
                  // marginRight: "10px",
                  marginLeft: "35px",
                }}
                IconComponent={GreenArrowIcon}
              >
                <MenuItem key={0} value={0} disabled>
                  {translations.BusinessUnit}
                </MenuItem>
                {companyRelations.teams.map((team) => (
                  <MenuItem key={team.id} value={team.id}>
                    {team.team_name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={4} display="flex" alignItems="center">
          <CustomTypography variant="h6" color="primary" fontSize={"16px"}>
            {translations.Favorite}
          </CustomTypography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            control={control as unknown as Control<FieldValues>}
            name="employee_group_id"
            render={({ field }) => (
              <Select
                variant="outlined"
                {...field}
                error={!!errors.employee_group_id}
                fullWidth
                sx={{
                  background: "rgba(112,112,112,0.05)",
                  width: "120px",
                  marginLeft: "35px",
                }}
                IconComponent={GreenArrowIcon}
              >
                <MenuItem key={0} value={0} disabled>
                  {translations.Favorite}
                </MenuItem>
                {employeeGroups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.group_name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Button
          fullWidth
          variant="outlined"
          sx={{
            borderRadius: "0.5rem",
            borderWidth: "2px",
          }}
          onClick={handleSubmit(onSubmit)}
        >
          <CustomSecondaryButtonText variant="h6">
            {translations.Search}
          </CustomSecondaryButtonText>
        </Button>
        <FormHelperText error={!!errors.require?.message}>
          {errors.require?.message}
        </FormHelperText>
      </Grid>
    </Grid>
  )
}
