import QRCode from "qrcode.react"

import React, { useEffect, useState } from "react"

import {
  Box,
  Grid,
  styled,
  Switch,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material"

import { QrObjectType } from "../../../../models/company/useQrCode/type"
import { Paging } from "../../../public/Pagination"

const QrCodeListWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(227, 227, 227, 0.5)",
  minHeight: "80%",
  marginTop: theme.spacing(1),
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2, 3),
}))

const QrCodeContainer = styled(Grid)(({ theme }) => ({
  width: "90%",
  margin: theme.spacing(2, "auto"),
}))

const CustomTableCell = styled(TableCell)(() => ({
  borderBottom: "none",
}))

interface Props {
  qrCodeList: QrObjectType[]
}

// QRコード画面：右下、QRコード一覧
export const CompanyQrCodeList = ({ qrCodeList }: Props) => {
  const [urlShow, setUrlShow] = useState<boolean>(false) // URL / QRコードの表示切り替え

  const [pageStatus, setPageStatus] = useState<number>(0) // 表示するデータの先頭のレコードが何番目なのかを管理する(0, 10, 20, 30...)
  const [pageNumber, setPageNumber] = useState<number>(1) // ページネーションコンポーネントで現在どのページが表示されているかを管理する
  const URL_COUNT_PER_PAGE = 5 // 1ページに表示するレコードの数
  const QR_COUNT_PER_PAGE = 8 // 1ページに表示するレコードの数

  // ページネーションを操作したときの状態を変更する
  const handleUrlPaginationChange = (
    e: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageStatus((page - 1) * URL_COUNT_PER_PAGE)
    setPageNumber(page)
  }

  // ページネーションを操作したときの状態を変更する
  const handleQrPaginationChange = (
    e: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageStatus((page - 1) * QR_COUNT_PER_PAGE)
    setPageNumber(page)
  }

  // ページネーションをリセット
  const handlePagenationReset = () => {
    setPageStatus(0)
    setPageNumber(1)
  }

  // QRコード/URLを切り替えるたびにページネーションをリセット
  useEffect(() => {
    handlePagenationReset()
  }, [urlShow])

  return (
    <QrCodeListWrapper>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="subtitle1" fontWeight="bold">
            URLを表示する
          </Typography>
        </Grid>
        <Grid item>
          <Switch onChange={() => setUrlShow(!urlShow)} />
        </Grid>
      </Grid>
      {urlShow ? (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "30%", minWidth: "200px" }}>
                    <Typography color="primary" fontWeight="bold">
                      座席名 / 会議室名
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: "70%", minWidth: "400px" }}>
                    <Typography color="primary" fontWeight="bold">
                      URL
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {qrCodeList
                  .slice(pageStatus, pageStatus + URL_COUNT_PER_PAGE)
                  .map((qrCodeObject, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          <CustomTableCell>
                            <Typography>
                              {qrCodeObject.floorName}-
                              {qrCodeObject.type === "Seat"
                                ? `座席${qrCodeObject.name}`
                                : qrCodeObject.name}
                            </Typography>
                          </CustomTableCell>
                          <CustomTableCell>
                            <Typography>{qrCodeObject.url}</Typography>
                          </CustomTableCell>
                        </TableRow>
                      </>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {qrCodeList.length > URL_COUNT_PER_PAGE && (
            <Paging
              pageCount={Math.ceil(qrCodeList.length / URL_COUNT_PER_PAGE)}
              page={pageNumber}
              handlePaginationChange={handleUrlPaginationChange}
            />
          )}
        </>
      ) : (
        <>
          <QrCodeContainer container spacing={1}>
            {qrCodeList
              .slice(pageStatus, pageStatus + QR_COUNT_PER_PAGE)
              .map((qrCodeObject, index) => {
                return (
                  <Grid item sm={3} key={index}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        width: "58px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        float: "left",
                      }}
                    >
                      {qrCodeObject.floorName}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={
                        qrCodeObject.type === "Seat"
                          ? {}
                          : {
                              width: "80px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }
                      }
                    >
                      -
                      {qrCodeObject.type === "Seat"
                        ? `座席${qrCodeObject.name}`
                        : qrCodeObject.name}
                    </Typography>
                    <QRCode value={qrCodeObject.url} />
                  </Grid>
                )
              })}
          </QrCodeContainer>
          <Box
            sx={{
              margin: "0 auto",
            }}
          >
            {qrCodeList.length > QR_COUNT_PER_PAGE && (
              <Paging
                pageCount={Math.ceil(qrCodeList.length / QR_COUNT_PER_PAGE)}
                page={pageNumber}
                handlePaginationChange={handleQrPaginationChange}
              />
            )}
          </Box>
        </>
      )}
    </QrCodeListWrapper>
  )
}
