import React, { useMemo, useState } from "react"

import {
  Box,
  Modal,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import {
  DragEmployee,
  Seat,
} from "../../../../../models/employee/useSchedule/useLayout/type"
import {
  WhoIsWhere,
  WhoIsWheres,
} from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import {
  unavailableStyle,
  freeSeatStyle,
  reservedSeatStyle,
} from "../../../../../styles/seatStyle"
import enTranslations from "../../../../../translations/errorMessage/en"
import jaTranslations from "../../../../../translations/errorMessage/ja"
import { formatDateForSafari } from "../../../../../utils/dateTimeFormat"
import { formatTime } from "../../../../../utils/time"
import { Paging } from "../../../../public/Pagination"

interface Props {
  seat: Seat
  whoIsWheres: WhoIsWheres
  setWhoIsWheres: React.Dispatch<React.SetStateAction<WhoIsWheres>>
  dragEmployee: DragEmployee
}

const EmployeeSearchModalOverlay = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: theme.spacing(80),
  height: theme.spacing(60),
  flexDirection: "column",
  justifyContent: "space-around",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(0.5),
}))

const CustomTableHeader = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
}))

interface TableHeader {
  id: number
  label: string
}
/*
  レイアウト画像の登録フォームで席のアイコンが表示される
*/
export const EmployeeCalendarLayoutSeat: React.FC<Props> = ({
  seat,
  whoIsWheres,
  setWhoIsWheres,
  dragEmployee,
}: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const employeeTableColumn: TableHeader[] = [
    { id: 1, label: translations.EmployeeName },
    { id: 2, label: translations.Email },
  ]
  const [seatTarget, setSeatTarget] = useState<HTMLElement | null>()
  const [reservedSeat, setReservedSeat] = useState<boolean>(false)
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const [seatStyle, setSeatStyle] = useState<React.CSSProperties | undefined>(
    undefined
  )
  const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false)
  const [pageStatus, setPageStatus] = useState<number>(0) // 表示するデータの先頭のレコードが何番目なのかを管理する(0, 5, 10, 15...)
  const [pageNumber, setPageNumber] = useState<number>(1) // ページネーションコンポーネントで現在どのページが表示されているかを管理する
  const DATA_COUNT_PER_PAGE = 5

  // ページネーションを操作したときの状態を変更する
  const handlePaginationChange = (
    e: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageStatus((page - 1) * DATA_COUNT_PER_PAGE)
    setPageNumber(page)
  }

  const handleOpen = () => {
    setIsSearchModalOpen(true)
  }

  const handleClose = () => {
    setIsSearchModalOpen(false)
  }

  const unavailableValidation = (): boolean => {
    if (dragEmployee.id && seat.reservable_employee_ids.length > 0) {
      return (
        !seat.schedule &&
        !seat.unavailable_flag &&
        seat.reservable_employee_ids.includes(dragEmployee.id)
      )
    } else {
      return !seat.schedule && !seat.unavailable_flag
    }
  }

  const canReserveSeat = (targetEmployeeId: number | undefined): boolean => {
    if (targetEmployeeId && seat.reservable_employee_ids.length > 0) {
      return (
        !seat.schedule &&
        !seat.unavailable_flag &&
        seat.reservable_employee_ids.includes(targetEmployeeId)
      )
    } else {
      return !seat.schedule && !seat.unavailable_flag
    }
  }

  // ステータスアイコンの色を変更
  const generateSeat = (x: number, y: number) => {
    for (let i = 0; i < whoIsWheres.length; i++) {
      if (
        whoIsWheres[i].reservable.reservable_type == "Seat" &&
        whoIsWheres[i].reservable.reservable_id === seat.id
      ) {
        if (seat.unavailable_flag) {
          return {
            ...unavailableStyle,
            top: y,
            left: x,
            backgroundColor: "#F4B461",
          }
        } else if (!seat.schedule) {
          return {
            ...freeSeatStyle,
            top: y,
            left: x,
            backgroundColor: "#F4B461",
          }
        } else {
          return {
            ...reservedSeatStyle,
            top: y,
            left: x,
            backgroundColor: "#F4B461",
          }
        }
      }
    }
    if (seat.unavailable_flag) {
      return {
        ...unavailableStyle,
        top: y,
        left: x,
        backgroundColor: "#fff",
      }
    } else if (!seat.schedule) {
      return {
        ...freeSeatStyle,
        top: y,
        left: x,
        backgroundColor: "#fff",
      }
    } else {
      return {
        ...reservedSeatStyle,
        top: y,
        left: x,
        backgroundColor: "#22BA9D",
      }
    }
  }

  useMemo(() => {
    setSeatTarget(document.getElementById(String(seat.id)))
  }, [seat.id])

  const onMouseEnter = () => {
    setTooltipOpen(true)
  }

  const onMouseLeave = () => {
    setTooltipOpen(false)
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, seat: Seat) => {
    if (unavailableValidation()) {
      whoIsWheres.map((whoIsWhere) => {
        if (whoIsWhere.userable.userable_id === dragEmployee.id) {
          whoIsWhere.reservable.reservable_id = Number(e.currentTarget.id)
          whoIsWhere.reservable.name = seat.seat_name
          whoIsWhere.reservable.reservable_type = "Seat"
        }
      })

      setWhoIsWheres([...whoIsWheres])
    }
  }

  const tooltipText = () => {
    if (seat.schedule) {
      const seatInfo = `${translations.Desk}:${seat.seat_name}\n`
      const employeeName = `${translations.Name}: ${seat.schedule?.userable.last_name} ${seat.schedule?.userable.first_name}\n`
      const scheduleTitle =
        seat.schedule?.schedule_type === "status"
          ? ""
          : `${translations.Subject}: ${seat.schedule?.schedule_title}\n`
      const scheduleTime = seat.schedule?.whole_day_flag
        ? translations.allDay
        : `${formatTime(
            formatDateForSafari(seat.schedule?.start_time.toString())
          )} ~ ${formatTime(
            formatDateForSafari(seat.schedule?.end_time.toString())
          )}`

      return `${seatInfo}${employeeName}${scheduleTitle}${scheduleTime}`
    } else {
      return ""
    }
  }

  const onClickIcon = () => {
    const isMeetingRoomReservation = whoIsWheres.some(
      (whoIsWhere) => whoIsWhere.reservable.reservable_type === "MeetingRoom"
    )

    if (isMeetingRoomReservation) {
      const initializedWhoIsWheres = whoIsWheres.map((whoIsWhere) => ({
        ...whoIsWhere,
        reservable: {
          reservable_id: undefined,
          reservable_type: "Seat",
          name: "",
        },
      }))
      setWhoIsWheres(initializedWhoIsWheres as WhoIsWheres)
    }

    // 利用不可の座席かどうかをチェック
    if (seat.seat_type === "unavailable") {
      alert("こちらの席は利用できません")
      return
    }

    // 指定席や指定されたエリアの座席の場合、予約可能な従業員かチェック
    if (seat.reservable_employee_ids.length > 0) {
      const canReserve = whoIsWheres.some((whoIsWhere) => {
        const userableId = whoIsWhere.userable.userable_id
        return (
          userableId !== undefined &&
          seat.reservable_employee_ids.includes(userableId)
        )
      })
      if (!canReserve) {
        alert("こちらの席は利用が制限されています")
        return
      }
    }

    // 席が予約可能かどうかをチェック
    if (seat.schedule) {
      alert("こちらの席は予約することができません")
      return
    }

    if (whoIsWheres.length === 1) {
      // 予約者が1人の場合の処理
      if (whoIsWheres[0].reservable.reservable_id === seat.id) {
        // すでに座席を選択している場合は選択解除
        whoIsWheres[0].reservable.reservable_id = undefined
        whoIsWheres[0].reservable.reservable_type = "Seat"
        whoIsWheres[0].reservable.name = ""
        setWhoIsWheres([...whoIsWheres])
        setSeatStyle({}) // スタイルをリセット
      } else {
        // 座席を選択していない場合は座席選択
        whoIsWheres[0].reservable.reservable_id = seat.id
        whoIsWheres[0].reservable.reservable_type = "Seat"
        whoIsWheres[0].reservable.name = seat.seat_name
        setWhoIsWheres([...whoIsWheres])
        setSeatStyle(generateSeat(seat.x, seat.y) as React.CSSProperties)
      }
    } else {
      // 予約者が複数いる場合の処理
      handleOpen()
    }
    return
  }

  const clickEmployee = (employee: WhoIsWhere) => {
    for (let i = 0; i < whoIsWheres.length; i++) {
      // 選択された従業員のプロパティを上書き
      if (
        whoIsWheres[i].userable.userable_id === employee.userable.userable_id
      ) {
        whoIsWheres[i].reservable.reservable_id = seat.id
        whoIsWheres[i].reservable.reservable_type = "Seat"
        whoIsWheres[i].reservable.name = seat.seat_name

        setWhoIsWheres([...whoIsWheres])
        setSeatStyle(generateSeat(seat.x, seat.y) as React.CSSProperties)
        // 既に他の従業員が選択されていた場合、プロパティを初期化
      } else if (whoIsWheres[i].reservable.reservable_id === seat.id) {
        whoIsWheres[i].reservable.reservable_id = undefined
        whoIsWheres[i].reservable.reservable_type = "Seat"
        whoIsWheres[i].reservable.name = ""

        setWhoIsWheres([...whoIsWheres])
        setSeatStyle(generateSeat(seat.x, seat.y) as React.CSSProperties)
      }
    }
    handleClose()
  }

  return (
    <>
      <Box
        id={String(seat.id)}
        className={"seat"}
        sx={generateSeat(seat.x, seat.y) as React.CSSProperties | undefined}
        onDragOver={unavailableValidation() ? handleDragOver : () => null}
        onDrop={(e) => {
          handleDrop(e, seat)
        }}
        onClick={onClickIcon}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {seat.schedule ? (
            <Tooltip
              title={tooltipText()}
              placement="bottom"
              disableInteractive
              open={tooltipOpen}
              componentsProps={{
                tooltip: {
                  sx: {
                    whiteSpace: "pre-wrap",
                    position: "fixed",
                    width: "100px",
                  },
                },
              }}
            >
              {seat.schedule.userable.image_blob ? (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                  src={seat.schedule.userable.image_blob}
                />
              ) : (
                <Typography
                  sx={{ fontSize: "20px" }}
                  color="inherit"
                  display="flex"
                >
                  <span>{seat.schedule.userable.last_name[0]}</span>
                  <span>{seat.schedule.userable.first_name[0]}</span>
                </Typography>
              )}
            </Tooltip>
          ) : (
            seat.seat_name
          )}
        </div>
      </Box>

      <Modal
        open={isSearchModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EmployeeSearchModalOverlay>
          <Box
            alignItems="center"
            sx={{
              height: "80%",
            }}
          >
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <Typography
                    component="h1"
                    fontSize="20px"
                    fontWeight="bold"
                    marginBottom={2}
                  >
                    {translations.selectMembers}
                  </Typography>
                  <TableRow>
                    {employeeTableColumn.map((headCell) => (
                      <CustomTableHeader key={headCell.id} padding={"normal"}>
                        {headCell.label}
                      </CustomTableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {whoIsWheres
                    .slice(pageStatus, pageStatus + DATA_COUNT_PER_PAGE)
                    .map((employee, index) => {
                      if (canReserveSeat(employee.userable.userable_id)) {
                        return (
                          <TableRow
                            hover
                            key={index}
                            onClick={() => {
                              clickEmployee(employee)
                            }}
                          >
                            <TableCell>
                              {employee.userable.last_name}
                              {employee.userable.first_name}
                            </TableCell>
                            <TableCell>{employee.userable.email}</TableCell>
                          </TableRow>
                        )
                      }
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {whoIsWheres.length > DATA_COUNT_PER_PAGE && (
              <Paging
                pageCount={Math.ceil(whoIsWheres.length / DATA_COUNT_PER_PAGE)}
                page={pageNumber}
                handlePaginationChange={handlePaginationChange}
              />
            )}
          </Box>
        </EmployeeSearchModalOverlay>
      </Modal>
    </>
  )
}
