import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { Box, Grid, Typography, styled, Modal, Button } from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import { useScheduleCheckIn } from "../../../../models/mobile/useSchedule/checkIn"
import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import { AuthContext } from "../../../../providers/AuthProvider"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { AlertError } from "../../../public/AlertError"
import { Loading } from "../../../public/Loading"
import { CheckInNotReservedModal } from "../CheckInNotReservedModal"

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  color: "#454545",
}))

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "85%",
  padding: theme.spacing(4),
}))

interface Props {
  companyRelations: CompanyRelationsType
}

// チェックインのメイン画面
export const CheckIn = ({ companyRelations }: Props) => {
  const { currentUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const locationSearch = useLocation().search
  const queryParams = new URLSearchParams(locationSearch)
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const {
    branchName,
    floorNumber,
    checkInType,
    checkInTarget,
    checkIn,
    checkInEndOfShift,
    checkInMobile,
    notReservedModalMessage,
    notReserved,
    setNotReserved,
    reservationModalHours,
    reservationModalMinutes,
    reservationHour,
    reservationMinutes,
    handleReservationHourChange,
    handleReservationMinutesChange,
    handleInstantCheckIn,
    checkInError,
    checkInErrorMessage,
    setCheckInError,
    setCheckInStateFromQueryString,
    hasAnotherSchedule,
    dataLoading,
    hasAnotherCheckIn,
  } = useScheduleCheckIn()

  const [startTime, setStartTime] = useState<string | null>(null) // startTimeを状態として管理
  const [employeeName, setEmployeeName] = useState<string | null>(null) // employeeNameを状態として管理
  const [employeeLastName, setEmployeeLastName] = useState<string | null>(null) // employeeLastNameを状態として管理
  const [employeeFirstName, setEmployeeFirstName] = useState<string | null>(
    null
  ) // employeeFirstNameを状態として管理
  const [sameUserFlag, setSameUserFlag] = useState<boolean>(false) // same_user_flagを状態として管理
  const [checkInFlag, setCheckInFlag] = useState<boolean>(false) // check_in_flagを状態として管理
  const [anotherScheduleFlag, setAnotherScheduleFlag] = useState<boolean>(false) // another_schedule_flagを状態として管理
  const [endOfShiftTimeOver, setEndOfShiftTimeOver] = useState<boolean>(false) // end_of_shift_time_overを状態として管理

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      if (companyRelations) {
        const data = await setCheckInStateFromQueryString(companyRelations)
        if (data) {
          setSameUserFlag(data.same_user_flag)
          setCheckInFlag(data.check_in_flag)
          setAnotherScheduleFlag(data.another_schedule_flag)
          setEndOfShiftTimeOver(data.end_of_shift_time_over)
        }
        if (
          data &&
          data.other_reservations &&
          data.other_reservations.length > 0
        ) {
          // 予約がある場合、最も早い予約の開始時間を取得
          console.log("data", data)
          const now = new Date()
          const futureReservations = data.other_reservations.filter(
            (reservation) => new Date(reservation.start_time) > now
          )

          if (futureReservations.length > 0) {
            const earliestReservation = futureReservations.reduce(
              (earliest, current) => {
                return new Date(current.start_time) <
                  new Date(earliest.start_time)
                  ? current
                  : earliest
              }
            )
            // 予約者名を取得
            setEmployeeName(earliestReservation.employee_name)
            setEmployeeLastName(earliestReservation.employee_last_name)
            setEmployeeFirstName(earliestReservation.employee_first_name)
            // startTimeを設定
            setStartTime(earliestReservation.start_time)
          }
          // 現在の時間で予約されているスケジュールがあるか確認
          const isCurrentlyReserved = data.other_reservations.some(
            (reservation) =>
              new Date(reservation.start_time) <= now &&
              new Date(reservation.end_time) >= now
          )
          if (
            data.my_reservation &&
            data.my_reservation.length > 0 &&
            data.my_reservation[0].reservable_id &&
            data.my_reservation[0].checked_in === false
          ) {
            // チェックインを実行する
            const mobileCheckIn = () => {
              if (data.my_reservation[0].reservable_id !== null) {
                checkInMobile({
                  seatId: data.my_reservation[0].reservable_id,
                })
              }
            }
            mobileCheckIn()
            window.location.href = `/mobile/check-in/completed?floor_id=${queryParams.get(
              "floor_id"
            )}&id=${queryParams.get(
              "id"
            )}&type=${checkInType}&name=${checkInTarget}`
            return
          } else if (isCurrentlyReserved) {
            window.location.href = `/mobile/check-in/already-reserved?floor_id=${queryParams.get(
              "floor_id"
            )}&id=${queryParams.get(
              "id"
            )}&type=${checkInType}&name=${checkInTarget}`
            return
          }
        }
      }
      setIsLoading(false)
    }
    fetchData()
  }, [companyRelations])

  // PrivateRouteだとクエリパラメータを読み込めないのでここでリダイレクトさせる
  useEffect(() => {
    if (!currentUser) {
      navigate(
        `/mobile/check-in/login?floor_id=${queryParams.get(
          "floor_id"
        )}&id=${queryParams.get("id")}&type=${queryParams.get(
          "type"
        )}&name=${queryParams.get("name")}`
      )
    }
  }, [])

  return (
    <>
      {isLoading || dataLoading ? (
        <Loading type="content" loading={isLoading || dataLoading} />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} display="flex" justifyContent="center" mx={2}>
            <CustomTypography variant="h5">{branchName}</CustomTypography>
            <CustomTypography variant="h5">{floorNumber}F</CustomTypography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center" mx={2}>
            {checkInType === "Seat" && (
              <CustomTypography variant="h4">
                {translations.Desk}：{checkInTarget}
              </CustomTypography>
            )}
            {checkInType === "MeetingRoom" && (
              <CustomTypography variant="h4">
                {translations.RoomName}：{checkInTarget}
              </CustomTypography>
            )}
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <CustomTypography>{translations.YouCheckIn}</CustomTypography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                checkIn()
              }}
            >
              <Typography fontWeight="bold">
                {translations.scheduleAndCheckIn}
              </Typography>
            </Button>
          </Grid>
          {checkInType !== "MeetingRoom" && (
            <>
              {!endOfShiftTimeOver && (
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      checkInEndOfShift()
                    }}
                  >
                    <Typography fontWeight="bold">
                      {startTime === null
                        ? translations.endOfShiftCheckIn
                        : startTime && sameUserFlag
                        ? translations.endOfReservationCheckIn
                        : language === "ja"
                        ? `${new Date(startTime).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}までチェックイン`
                        : `CHECK IN UNTIL ${new Date(
                            startTime
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}`}
                    </Typography>
                  </Button>
                </Grid>
              )}
              {employeeName && startTime && (
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Typography variant="h6" align="center" fontWeight="bold">
                    {language === "ja"
                      ? `この座席は、${employeeName}さんが
                    ${new Date(startTime).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                    から予約中です`
                      : `This seat is reserved for ${employeeFirstName} ${employeeLastName} from ${new Date(
                          startTime
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}`}
                  </Typography>
                </Grid>
              )}
              {checkInFlag && (
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Typography variant="h6" align="center" fontWeight="bold">
                    {translations.CheckInNow}
                  </Typography>
                </Grid>
              )}
              {!checkInFlag && anotherScheduleFlag && (
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Typography variant="h6" align="center" fontWeight="bold">
                    {translations.ReserveInNow}
                  </Typography>
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => navigate("/mobile/layouts")}
            >
              <Typography fontWeight="bold">
                {translations.DeskStatus}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => navigate("/mobile/home")}
            >
              <Typography fontWeight="bold">{translations.Home}</Typography>
            </Button>
          </Grid>
          <Modal
            open={notReserved}
            onClose={() => setNotReserved(false)}
            disableAutoFocus
          >
            <ModalBox>
              <CheckInNotReservedModal
                notReservedModalMessage={notReservedModalMessage}
                hours={reservationModalHours}
                minutes={reservationModalMinutes}
                reservationHour={reservationHour}
                reservationMinutes={reservationMinutes}
                handleReservationHourChange={handleReservationHourChange}
                handleReservationMinutesChange={handleReservationMinutesChange}
                setNotReserved={setNotReserved}
                handleInstantCheckIn={handleInstantCheckIn}
                hasAnotherSchedule={hasAnotherSchedule}
                hasAnotherCheckIn={hasAnotherCheckIn}
              />
            </ModalBox>
          </Modal>
          <AlertError
            isMessageOpen={checkInError}
            alertMessage={checkInErrorMessage}
            closeMessage={() => setCheckInError(false)}
          />
        </Grid>
      )}
    </>
  )
}
